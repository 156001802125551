.dialog {
    outline: none;
    @apply mx-auto;
}
.dialog-scrum {
    outline: none;
    width: 600px;
    @apply ml-auto;
}

@screen sm {
    .dialog {
        @apply max-w-xl;
    }
}

@screen md {
    .dialog {
        @apply max-w-2xl;
    }
}

@screen lg {
    .dialog {
        @apply max-w-4xl;
    }
}

@screen xl {
    .dialog {
        @apply max-w-6xl;
    }
}

.dialog-content {
    @apply p-6 rounded-lg shadow-xl sm:my-16 relative h-full bg-white ;
}
.dialog-content-scrum {
    @apply w-[600px] p-8 rounded-lg shadow-xl relative h-full bg-white ;
}

.dialog-overlay {
    transition: all 0.15s ease-in-out;
    @apply bg-opacity-60 inset-0 fixed z-30 bg-black ;
}

.dialog-overlay-after-open {
    opacity: 1;
}

.dialog-overlay-before-close {
    opacity: 0;
}