.menu-item {
    @apply cursor-pointer font-semibold px-3 rounded-md flex items-center w-full whitespace-nowrap gap-x-11;

    &.menu-item-light {
        @apply text-gray-600;

        /* &.menu-item-hoverable {
            @apply hover:text-gray-900 hover:bg-custom-light-gradient;
        } */

        /* &.menu-item-active {
            @apply text-white bg-custom-gradient hover:bg-custom-gradient;
        } */
    }

    &.menu-item-dark {
        @apply text-gray-400 ;

        /* &.menu-item-hoverable {
            @apply hover:text-white hover:bg-custom-gradient;
        } */

        /* &.menu-item-active {
            @apply text-white bg-custom-gradient hover:bg-custom-gradient;
        } */
    }

    &.menu-item-themed {
        @apply text-gray-100 text-opacity-80;

        /* &.menu-item-hoverable {
            @apply hover:text-gray-900 hover:bg-custom-light-gradient;
        } */

        /* &.menu-item-active {
            @apply text-white bg-custom-gradient hover:bg-custom-gradient;
        } */
    }

    &.menu-item-transparent {
        @apply text-gray-600 ;

        /* &.menu-item-hoverable {
            @apply hover:text-gray-900 hover:bg-custom-light-gradient;
        } */

        /* &.menu-item-active {
            @apply text-white bg-custom-gradient hover:bg-custom-gradient;
        } */
    }
}

.menu-collapse-item {
    @apply cursor-pointer select-none font-semibold px-3 h-10 rounded-md flex items-center justify-between;

    &.menu-collapse-item-light {
        @apply text-gray-600 hover:text-gray-900 hover:bg-gray-100;
    }

    &.menu-collapse-item-dark {
        @apply text-gray-400  hover:text-gray-100  hover:bg-gray-700;
    }

    &.menu-collapse-item-themed {
        @apply text-gray-100 text-opacity-80 hover:text-opacity-100 hover:bg-gray-900 hover:bg-opacity-10;
        ;
    }

    &.menu-collapse-item-transparent {
        @apply text-gray-600 hover:text-gray-900 hover:bg-gray-900/[.1]   ;
    }
}

.menu-item-divider {
    @apply border-b border-gray-200  my-2;
}

.menu-item-disabled {
    @apply cursor-not-allowed opacity-60;
}

.menu-item-link {
    @apply flex w-full h-full items-center;
}