@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}



.bg-img {
  background-image: url("/src/assets/images/cleo-bg.png");
  object-fit: cover;
  background-repeat: round;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.5);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(100, 100, 100, 0.7);
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(240, 240, 240, 0.3);
  border-radius: 10px;
}