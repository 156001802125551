.side-nav {
    @apply flex-col flex-auto flex-shrink-0 z-20 transition-all ease-in-out duration-200;
}

.side-nav-expand {
    @apply sticky top-0 h-screen;
}

.side-nav-content {
    height: calc(100vh - theme('spacing.16'));
    @apply overflow-y-auto;
}

.side-nav-light {
    @apply bg-white border-r border-gray-200;
}

.side-nav-transparent {
    @apply bg-transparent;
}