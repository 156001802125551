.menu-title {
    @apply uppercase font-semibold px-3 mt-4 mb-2;

    &.menu-title-light {
        @apply text-gray-500;
    }

    &.menu-title-dark {
        @apply text-gray-300;
    }

    &.menu-title-themed {
        @apply text-gray-100 text-opacity-50;
    }
}

.menu-light {
    @apply bg-white;
}

.menu-dark {
    @apply bg-gray-800;
}

.menu-transparent {
    @apply bg-transparent;
}

[data-testid="ar_cancel"][title="Discard Recording"] {
    display: none;
}


[data-testid="ar_pause"][title="Pause recording"] {
    margin-right: 10px;
}

[data-testid="ar_pause"][title="Resume recording"] {
    margin-right: 10px;
}