.switcher {
    min-width: 2.75rem;
    height: 1.5rem;
    @apply inline-flex items-center relative rounded-3xl bg-gray-200  cursor-pointer transition-colors ease-in-out duration-200;

    input {
        @apply hidden;
    }

    &.switcher-checked {
        .switcher-toggle {
            left: calc(100% - 1.25rem - 0.125rem);
        }

        .switcher-toggle-loading {
            left: calc(100% - 1.25rem - 0.125rem);
        }

        .switcher-content {
            margin: 0 1.65rem 0 .45rem;
        }
    }

    &.switcher-disabled {
        @apply opacity-50 cursor-not-allowed;
    }
}

.switcher-toggle {
    left: 0.125rem;
    top: 0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    @apply absolute transition-all ease-in-out duration-200;

    &:before {
        content: "";
        @apply absolute left-0 right-0 bottom-0 top-0 bg-white rounded-xl;
    }
}

.switcher-content {
    margin: 0 .45rem 0 1.65rem;
    @apply text-white transition-colors ease-in-out duration-200;
}

.switcher-toggle-loading {
    width: 1.25rem;
    height: 1.25rem;
    left: 0.125rem;
    top: 0.125rem;
    @apply absolute transition-all ease-in-out duration-200;
}

.switcher-checked-loading {
    @apply !text-white;
}


/* .switcher-uncheck-loading {
    @apply dark:!text-white;
} */