.drawer-content {
    @apply bg-[#f8f8f8]  shadow-xl absolute flex flex-col rounded-t-3xl;

    &.vertical {
        @apply h-full;
    }

    &.horizontal {
        @apply w-full;
    }
}

.drawer-header {
    @apply flex items-center justify-between py-2 px-3 border-b border-gray-200 ;
}

.drawer-body-custom {
    @apply min-h-screen h-screen overflow-y-hidden px-6;
}
.drawer-body-header {
    @apply pt-6 h-[150px]
}
.drawer-body-content {
    height: calc(100vh - 174px);
    @apply w-full
}
.drawer-body-tab-content {
    height: calc(100vh - 174px - 60px);
    @apply w-full 
}
.drawer-body {
    @apply p-0 h-full overflow-y-auto;
}

.drawer-footer {
    @apply flex items-center justify-between py-4 px-6 border-t border-gray-200 ;
}

.drawer-open {
    &.drawer-lock-scroll {
        @apply overflow-hidden;
    }
}

.drawer-overlay {
    transition: all 0.3s ease-in-out;
    @apply bg-black bg-opacity-80 inset-0 fixed z-30;
}

.drawer-overlay-after-open {
    opacity: 1;
}

.drawer-overlay-before-close {
    opacity: 0;
}