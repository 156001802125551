.segment {
    @apply flex;

    > .segment-item-default {

        &:first-child {
            @apply ltr:rounded-r-none rtl:rounded-l-none;
        }

        &:last-child:not(.picker) {
            @apply ltr:rounded-l-none rtl:rounded-r-none;
        }

        &:not(:first-child) {
            @apply ltr:rounded-l-none ltr:-ml-px rtl:rounded-r-none rtl:-mr-px;
        }

        &:not(:last-child) {
            @apply ltr:rounded-r-none rtl:rounded-l-none;
        }
    }
}

.segment-item-default {
    @apply
    font-semibold 
    focus:outline-none
    cursor-pointer
    select-none
    rounded-md
    bg-white
    border 
    border-gray-300 
     
    
    text-gray-600 
    
    w-full
    md:w-auto;

    &:not(.segment-item-active):not(.segment-item-disabled) {
        @apply 
        hover:bg-gray-50 
         
        active:bg-gray-100 
         
        ;
    }

    &.segment-item-active {
        @apply bg-gray-100  ;
    }

    &.segment-item-disabled {
        @apply cursor-not-allowed opacity-40;
    }
}
