@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply text-gray-500  text-sm bg-gray-100  leading-normal;
        -webkit-font-smoothing: antialiased;
    }

    h1, .h1 {
        @apply text-4xl font-bold text-gray-900 ;
    }

    h2, .h2 {
        @apply text-3xl font-bold text-gray-900 ;
    }

    h3, .h3 {
        @apply text-2xl font-semibold text-gray-900 ;
    }

    h4, .h4 {
        @apply text-xl font-semibold text-gray-900 ;
    }

    h5, .h5 {
        @apply text-lg font-semibold text-gray-900 ;
    }

    h6, .h6 {
        @apply text-base font-semibold text-gray-900 ;
    }

    hr {
        @apply border-gray-200 ;
    }

    .heading-text {
        @apply text-gray-900 ;
    }
}