.card {
    @apply bg-white rounded-lg ;
}

.card-border {
    @apply border border-gray-200 ;
}

.card-shadow {
    @apply shadow border-b border-gray-200  ;
}

.card-header-border {
    @apply border-b border-gray-200 ;
}

.card-header-extra {
    @apply flex justify-between items-center;
}

.card-footer {
    @apply rounded-bl-lg rounded-br-lg;
}

.card-footer-border {
    @apply border-t border-gray-200 ;
}

.card-header,
.card-footer {
    @apply py-3 px-5;
}

.card-body {
    @apply p-0;
}

.card-gutterless {
    @apply p-0 h-full;
}
/* TRUNCATE ON THIRD LINE */
.lineClamp3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;     
    text-overflow: ellipsis;
    margin-top: 0.5rem;     
    width: 100%;             
    font-size: 0.875rem;     
    line-height: 1.25rem;    
    color: #6B7280;
}