.header {
    @apply bg-white flex z-30 sticky top-0 w-full;
}

.header-wrapper {
    @apply flex relative items-center justify-between p-0 px-4 w-full;
}

.header-action {
    @apply flex items-center;
}

.header-action-item {
    @apply cursor-pointer p-2 rounded-full mx-1;
}

.header-action-item-hoverable {
    @apply hover:bg-black hover:bg-opacity-5 hover:text-gray-900 transition-colors duration-300 ease-in-out;
}