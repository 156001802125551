.skeleton {
    @apply bg-gray-200  flex;
}

.skeleton-circle {
    @apply rounded-full w-10 h-10;
}

.skeleton-block {
    @apply rounded h-2.5 w-full;
}